$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: map-get($colors, 'color_1') !important;
}

::-moz-placeholder { /* Firefox 19+ */
  color: map-get($colors, 'color_1') !important;
}

:-ms-input-placeholder { /* IE 10+ */
  color: map-get($colors, 'color_1') !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: map-get($colors, 'color_1') !important;
}

#toast {
  z-index: 11000 !important;
}

.address-go-to-store-section {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.error-recaptcha ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.error-recaptcha ul li {
  margin-top: 10px;
  color: map-get($colors, 'color_5');
  font-size: 12px;
}

.cookieAcceptBar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  background-color: map-get($colors, 'color_1');
  padding: 20px 0;
  z-index: 99999;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.cookieAcceptBar #cookieAcceptBarConfirm {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: map-get($colors, 'color_6');
  margin-right: 30px;
  border: 0 !important;
  padding: 0;
  background-color: transparent !important;
  display: inline-flex;
  text-transform: uppercase;
}

.cookieAcceptBar #cookieDeclineBarConfirm {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  border: 0 !important;
  padding: 0;
  background-color: transparent !important;
  display: inline-flex;
  text-transform: uppercase;
}

.cookieAcceptBar .buttons {
  width: 100%;
  margin-top: 20px;
}

body, html {
  background-color: #F4F4F4 !important;
  width: 100%;
  height: auto;
}


.left-column {
  display: inline-flex;
  margin-right: auto;
}

.right-column {
  display: inline-flex;
  margin-left: auto;
}

.center-column {
  display: inline-flex;
  margin-right: auto;
}

.site-logo {
  background-image: url('/bundles/_themes/ascend/all-in-one-theme/syliusshop/img/BringoLogoBig.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 224px;
  height: 70px;
  display: flex;
}

.user-avatar-icon {
  background-image: url($themeVariantImgPath + '/homepage/user-avatar-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: flex;
  margin-right: 10px;
}

.center-location-icon {
  background-image: url($themeVariantImgPath + '/homepage/center-location-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: flex;
}

.pin-location-icon {
  background-image: url($themeVariantImgPath + '/homepage/pin-location-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 10px;
}

.home-location-icon {
  background-image: url($themeVariantImgPath + '/homepage/home-location-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 10px;
}

nav .bringo-my-account > button.dropdown-toggle:after {
  background-image: url($themeVariantImgPath + '/homepage/arrow-dropdown-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 8px;
  height: 5px;
  display: flex;
  margin-left: 10px;
}

nav .bringo-my-account.show > button.dropdown-toggle:after,
nav .bringo-my-account:hover > button.dropdown-toggle:after {
  background-image: url($themeVariantImgPath + '/homepage/arrow-dropdown-icon.svg');
}

.arrow-right-white-icon {
  background-image: url($imgPath + "/homepage/arrow-right-white-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: flex;
  margin-left: 12px;
}

nav {
  display: flex !important;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  z-index: 100;
}

nav .left-column,
nav .right-column {
  position: relative;
  z-index: 10;
}

nav .center-column {
  display: inline-flex;
  position: absolute;
  z-index: 5;
  left: auto;
  right: auto;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: map-get($units, 'nav_center_column_mt');
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-flex;
  padding-right: 30px;
}

nav ul li a {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  text-decoration: none !important;
  position: relative;
}

nav ul li.active a:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  background: map-get($colors, 'color_6');
  width: 27px;
  height: 3px;
}

nav ul li.active a {
  color: map-get($colors, 'color_6');
}

nav .bringo-my-account > button.dropdown-toggle {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: map-get($colors, 'color_1') !important;
  box-shadow: none !important;
  padding: 0;
  background: transparent !important;
  border: 0;
  display: flex;
  align-items: center;
}

nav .bringo-my-account .dropdown-menu {
  padding: 0;
  border: 0;
  background: #FFFFFF;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: auto;
  max-width: 230px;
  margin-top: 15px;
  margin-right: 5px;
}

nav .bringo-my-account .dropdown-menu .login-div {
  border-radius: 15px 15px 0 0;
  background: map-get($colors, 'color_1');
  padding: 20px;
}

nav .bringo-my-account .dropdown-menu .login-div a {
  background: map-get($colors, 'color_6');
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF !important;
  padding: 13px 40px;
  text-decoration: none !important;
  display: flex;
  white-space: nowrap;
}

nav .bringo-my-account .dropdown-menu .create-account-div {
  padding: 15px 20px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_4');
}

nav .bringo-my-account .dropdown-menu .create-account-div .register-btn {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1') !important;
  text-decoration: none !important;
  display: inline-flex;
  margin-top: 8px;
}

nav .bringo-my-account .dropdown-menu .hello_fullname {
  border-radius: 15px 15px 0 0;
  background: map-get($colors, 'color_1');
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 12px 20px;
  white-space: nowrap;
}

nav .bringo-my-account .dropdown-menu ul {
  padding: 5px 0;
}

nav .bringo-my-account .dropdown-menu ul li.logout-btn {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

nav .bringo-my-account .dropdown-menu ul li {
  display: flex;
  padding: 5px 20px;
}

nav .bringo-my-account .dropdown-menu ul li a {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  text-decoration: none !important;
  display: flex;
  white-space: nowrap;
}

nav .bringo-my-account .dropdown-menu:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

nav .bringo-my-account .dropdown-menu:before {
  border-color: transparent;
  border-bottom-color: map-get($colors, 'color_1');
  border-width: 5px;
  margin-left: -5px;
  right: 20px;
}

header {
  margin-top: 60px;
  margin-bottom: 70px;
}

header::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  background-image: url('/bundles/_themes/ascend/all-in-one-theme/syliusshop/img/banner-image-hp-bringo-left-wide.png');
  width: 100%;
  height: 1000px;
}

header::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  background-image: url('/bundles/_themes/ascend/all-in-one-theme/syliusshop/img/banner-image-hp-bringo-right-wide.png');
  width: 100%;
  height: 1000px;
}

header h1 {
  margin-top: 0;
  margin-bottom: 40px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: map-get($colors, 'color_6');
  position: relative;
  z-index: 5;
}

header h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  position: relative;
  z-index: 5;
}

header .address-section {
  position: relative;
  z-index: 5;
  min-height: 109px;
}

header .address-section .input-group.error-group {
  justify-content: center;
}

header .address-section .input-group.error-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

header .address-section .input-group.error-group ul li {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: map-get($colors, 'color_5');
}

header .address-section .address-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

header .address-section .address-box .geolocation {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  margin-right: 18px;
}

header .address-section .address-box div:first-child {
  border-radius: 15px;
}

header .address-section .address-box div:nth-child(2) {
  border-radius: 15px 0 0 15px;
}

header .address-section .address-box .address {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  padding: 0 25px;
}

header .address-section .address-box .address input {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: map-get($colors, 'color_1');
  height: 70px;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
  margin-right: 5px;
}

header .address-section .address-box .number {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  padding: 0 25px;
  width: 200px;
}

header .address-section .address-box .number input {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: map-get($colors, 'color_1');
  height: 70px;
  border: 0;
  width: 110px;
  box-shadow: none !important;
  outline: none !important;
}

header .address-section .address-box .btn-view-stores {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  background: map-get($colors, 'color_6');
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 15px 15px 0;
  padding: 12px 30px;
  border: 0;
  height: 70px;
  display: inline-flex;
  align-items: center;
}

header .address-section .landing-account-section {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

header .address-section .landing-account-section a {
  font-weight: 500;
  color: map-get($colors, 'color_1') !important;
  text-decoration: none !important;
}

.store-box-icon {
  background-image: url($themeVariantImgPath + '/homepage/store-box-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 87px;
  height: 87px;
  display: flex;
}

.tracking-box-icon {
  background-image: url($themeVariantImgPath + '/homepage/tracking-box-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 87px;
  height: 87px;
  display: flex;
}

.time-box-icon {
  background-image: url($themeVariantImgPath + '/homepage/time-box-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 87px;
  height: 87px;
  display: flex;
}

.products-box-icon {
  background-image: url($themeVariantImgPath + '/homepage/products-box-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 87px;
  height: 87px;
  display: flex;
}

.about-bringo .box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.about-bringo h2 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-bringo p {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin: 0;
}

.stores h3 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-top: 50px;
  margin-bottom: 30px;
}

.stores .box {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px;
  padding: 10px;
}

.arrow-right-colored-icon {
  background-image: url($themeVariantImgPath + '/homepage/arrow-right-colored-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: flex;
  margin-left: 8px;
}

.stores .btn-view-all-stores {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.stores .btn-view-all-stores a {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: map-get($colors, 'color_1');
  padding: 18px 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
}

.find-in-country .box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.find-in-country h3 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-bottom: 25px;
}

.find-in-country .box .letter {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-bottom: 5px;
}

.find-in-country .box .city {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-bottom: 15px;
}

.find-in-country .col-lg-10.col-xl-10 .col-lg-2.col-xl-2 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.download-app {
  margin-top: 50px;
  margin-bottom: 40px;
}

.download-app .box h3 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.download-app .box .description {
  width: 400px;
}

.download-app .box {
  position: relative;
  background-image: url($themeVariantImgPath + '/homepage/download-app-bg-image.jpg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  width: 100%;
  height: 100%;
  /* Elevation 4dp */
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)), drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  border-radius: 15px;
  padding: 30px;
}

.download-app .box a {
  display: inline-flex;
  margin-right: 10px;
}

.app-store-btn-icon {
  background-image: url($imgPath + "/homepage/app-store-btn-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 40px;
  display: flex;
}

.google-play-btn-icon {
  background-image: url($imgPath + "/homepage/google-play-btn-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 40px;
  display: flex;
}

.app-gallery-btn-icon {
  background-image: url($imgPath + "/homepage/app-gallery-btn-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 120px;
  height: 40px;
  display: flex;
}

.download-app .box img {
  max-width: 256px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.newsletter .box {
  padding: 25px 85px;
  background: map-get($colors, 'color_6');
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.newsletter .box h3 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-top: 0;
  margin-bottom: 30px;
}

.newsletter .box input {
  background: #FFFFFF;
  border-radius: 15px 0px 0px 15px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: map-get($colors, 'color_1');
  height: 56px;
  padding: 10px 20px;
}

.newsletter .box .input-section {
  display: flex;
  margin-bottom: 18px;
}

.newsletter .box button {
  background: map-get($colors, 'color_1');
  border-radius: 0px 15px 15px 0px;
  padding: 0 30px;
  height: 56px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  border: 0;
  min-width: 190px;
  display: inline-flex;
  align-items: center;
}

.newsletter .box .custom-control-label {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  padding-left: 10px;
}

.newsletter .box .custom-control-label a {
  font-weight: 500;
  color: map-get($colors, 'color_1') !important;
  text-decoration: none !important;
}

.newsletter .box .custom-control-label::before,
.newsletter .box .custom-control-label::after {
  outline: none !important;
  box-shadow: none !important;
}

.newsletter .box .custom-control-label::before {
  top: -2px !important;
  width: 24px !important;
  height: 24px !important;
  border: 2px solid map-get($colors, 'color_1') !important;
  background-color: transparent !important;
  border-radius: 100% !important;
}

.newsletter .box .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 2px !important;
  left: 4px !important;
  width: 16px !important;
  height: 16px !important;
  background-image: none !important;
  background-color: map-get($colors, 'color_1') !important;
  border-radius: 100% !important;
}


#modalAddressGoToStore .modal-dialog.modal-lg {
  max-width: 1000px;
}

#modalAddressGoToStore .modal-content {
  background: #F4F4F4;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: 0;
}

.close-modal-icon {
  background-image: url($themeVariantImgPath + '/homepage/close-modal-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
  display: flex;
}

#modalAddressGoToStore button.close {
  position: absolute;
  z-index: 15;
  right: 30px;
  top: 30px;
  text-shadow: none !important;
  opacity: 1 !important;
}

#modalAddressGoToStore .modal-header {
  border: 0;
  padding: 0;
}

.location-pin-big-icon {
  background-image: url($themeVariantImgPath + '/homepage/location-pin-big-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 146px;
  height: 146px;
  display: flex;
}

#modalAddressGoToStore .modal-body h5 {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-top: 30px;
  margin-bottom: 30px;
}

#modalAddressGoToStore .modal-body {
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#modalAddressGoToStore .address-section .address-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

#modalAddressGoToStore .address-section .address-box .geolocation {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  margin-right: 18px;
}

#modalAddressGoToStore .address-section .address-box .address {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 0 25px;
}

#modalAddressGoToStore .address-section .address-box .address input {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: map-get($colors, 'color_1');
  height: 70px;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
}

#modalAddressGoToStore .address-section .address-box .number {
  display: inline-flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px 0 0 15px;
  padding: 0 25px;
  width: 200px;
}

#modalAddressGoToStore .address-section .address-box .number input {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: map-get($colors, 'color_1');
  height: 70px;
  border: 0;
  width: 110px;
  box-shadow: none !important;
  outline: none !important;
}

#modalAddressGoToStore .address-section .address-box .btn-view-stores {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  background: map-get($colors, 'color_6');
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 15px 15px 0;
  padding: 12px 30px;
  border: 0;
  height: 70px;
  display: inline-flex;
  align-items: center;
  width: 230px;
}


header.contact-page:after {
  top: auto;
  bottom: 0;
}

header.contact-page:before {
  top: auto;
  bottom: 0;
}

header.contact-page .form-contact {
  position: relative;
  z-index: 5;
}

.contact-page textarea.form-control,
.contact-page input.form-control {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: map-get($colors, 'color_1');
  padding: 18px 20px;
  margin-bottom: 30px;
}

.contact-page .custom-control-label {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  padding-left: 10px;
  margin-bottom: 15px;
}

.contact-page .custom-control-label a {
  font-weight: 500;
  color: map-get($colors, 'color_1') !important;
  text-decoration: none !important;
}

.contact-page .custom-control-label::before,
.contact-page .custom-control-label::after {
  outline: none !important;
  box-shadow: none !important;
}

.contact-page .custom-control-label::before {
  top: -2px !important;
  width: 24px !important;
  height: 24px !important;
  border: 2px solid map-get($colors, 'color_1') !important;
  background-color: transparent !important;
  border-radius: 100% !important;
}

.contact-page .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 2px !important;
  left: 4px !important;
  width: 16px !important;
  height: 16px !important;
  background-image: none !important;
  background-color: map-get($colors, 'color_1') !important;
  border-radius: 100% !important;
}

.contact-page .btn-send {
  background: map-get($colors, 'color_1');
  border-radius: 15px;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  border: 0;
  display: inline-flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  padding: 18px 30px;
  margin-top: 15px;
}

.contact-page .select2-container {
  margin-bottom: 30px;
}

.contact-page .select2-container--default .select2-selection--single {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  height: auto;
}

.contact-page .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: map-get($colors, 'color_1');
  padding: 18px 20px;
}

.contact-page .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: map-get($colors, 'color_1');
}

.contact-page .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 58px;
}

.contact-page .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url($themeVariantImgPath + '/homepage/arrow-dropdown-big-icon.svg');
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  right: 12px;
}

.select2-dropdown {
  border-radius: 0 0 15px 15px !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
}

.select2-dropdown .select2-results__options {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: map-get($colors, 'color_1');
  background-color: transparent !important;
  padding: 15px 20px !important;
}

.mobile-city {
  display: none;
}

@media (min-width: 767px) and (max-width: 870px) {
  body {
    &.bringoRomania-theme,
    &.bringoMorocco-theme {
      .site-logo {
        width: 164px;
        height: 51px;
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  body {
    &.okMarket-theme {
      .site-logo {
        width: 98px;
        height: 17px;
      }
    }

    &.bringoRomania-theme {
      .site-logo {
        width: 205px;
        height: 64px;
      }
    }
  }
}

@media (max-width: 767px) {
  #apply-step5 > .row > div {
    padding: 0;
  }
  #apply-step5 .form-check {
    padding-left: 0;
  }
  .stores h3 {
    font-size: 22px;
    line-height: 27px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .find-in-country h3 {
    font-size: 22px;
    line-height: 27px;
  }
  .stores .box {
    margin-bottom: 20px;
    height: 180px;
    margin: 5px;
    padding: 10px;
  }
  .stores .btn-view-all-stores a {
    font-size: 17px;
    line-height: 20px;
    color: map-get($colors, 'color_1');
    padding: 16px 20px;
  }
  header {
    margin-top: 80px;
    margin-bottom: 180px;
  }
  header h1 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 80px;
  }
  header h2 {
    margin-top: 10px;
    margin-bottom: 60px;
    font-size: 22px;
    line-height: 26px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  nav ul {
    display: none;
  }
  header .address-section .address-box {
    flex-direction: column;
  }
  header .address-section .address-box .address {
    width: 100%;
    margin-bottom: 10px;
  }
  header .address-section .address-box .number {
    border-radius: 15px;
    width: 100%;
    margin-bottom: 10px;
  }
  header .address-section .address-box .btn-view-stores {
    border-radius: 15px;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .download-app .box .description {
    width: 100%;
  }
  nav .center-column {
    top: 30px;
    left: 0;
    right: 0;
  }
  header::before {
    top: 460px;
    height: 280px;
  }
  header::after {
    top: 460px;
    height: 280px;
  }
  .find-in-country .col-lg-10.col-xl-10 .col-lg-2.col-xl-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .desktop-city {
    display: none;
  }
  .mobile-city {
    display: block;
  }
  .download-app .box {
    padding: 20px;
  }
  .download-app .box .buttons {
    display: flex;
    flex-direction: column;
  }
  .download-app .box img {
    max-width: 200px;
    right: -15px;
  }
  .download-app .box a {
    margin-right: 0;
    margin-top: 10px;
  }
  .download-app .box h3 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .download-app {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .find-in-country .box {
    margin-bottom: 0;
  }
  header.contact-page h2 {
    margin-bottom: 40px;
  }
  .contact-page textarea.form-control, .contact-page input.form-control {
    margin-bottom: 10px;
  }
  .contact-page .select2-container {
    margin-bottom: 10px;
  }
  header.contact-page {
    margin-bottom: 100px;
  }
  header.contact-page:before {
    bottom: -150px;
  }
  header.contact-page:after {
    bottom: -150px;
  }
  .cookieAcceptBar {
    padding: 15px;
  }
  #modalAddressGoToStore .modal-body h5 {
    font-size: 20px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .location-pin-big-icon {
    width: 100px;
    height: 100px;
  }
  #modalAddressGoToStore .address-section .address-box {
    flex-direction: column;
  }
  #modalAddressGoToStore .address-section .address-box .address {
    width: 100%;
    margin-bottom: 10px;
  }
  #modalAddressGoToStore .address-section .address-box .number {
    border-radius: 15px;
    width: 100%;
    margin-bottom: 10px;
  }
  #modalAddressGoToStore .address-section .address-box .btn-view-stores {
    border-radius: 15px;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  header .address-section .address-box .number input,
  header .address-section .address-box .address input {
    width: 100%;
  }
}

.okMarket-theme {
  .service-description {
    max-width: 1080px;
    text-align: center;

    img {
      width: 100%;
      max-width: 80px;
    }

    .top-box {
      margin-bottom: 1rem;
    }

    .text {
      text-transform: uppercase;
      color: map-get($colors, 'color_1');
      font-size: 1.75rem;
    }

    .box {
      font-size: 1.2rem;
    }

    @media (max-width: 992px) {
      .box {
        margin: 2rem 0;
      }
    }

    .top-box {
      display: flex;

      @media (max-width: 1459px) {
        flex-direction: column;

        .image,
        .text {
          justify-content: center;
        }

        .text {
          margin: 1rem 0 1rem !important;
        }

        .image {
          min-height: 100px;
        }
      }

      justify-content: center;
      min-height: 100px;

      .image,
      .text {
        display: flex;
        align-items: center;
      }

      .text {
        margin-left: 1rem;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      i {
        color: map-get($colors, 'color_5');
      }

      li {
        margin-bottom: .5rem;
      }
    }
  }

  .simple-landing-page-header {
    @media (max-width: 768px) {
      .smartphone {
        text-align: center;
      }

      .stores {
        justify-content: center !important;
        margin-bottom: 3rem;

        i {
          width: 160px !important;
          height: 50px !important;
        }
      }

      header {
        margin-top: 0 !important;
      }
    }

    padding-bottom: 5rem;
    margin-bottom: 3rem;
    background: white;
    box-shadow: 1px 1px 10px #00000012;

    header {
      margin-top: 3rem;
      margin-bottom: 0;
    }

    .logo-wrapper {
      margin: 0 1rem 1rem;
      padding: 2rem 0;
      text-align: center;

      img {
        width: 100%;
        max-width: 400px;
      }
    }

    h2 {
      &:nth-child(2) {
        margin-bottom: 0;
      }

      font-size: 2rem;
      width: inherit;
      text-shadow: 2px 2px 0px #ffffff6b;
    }

    .mobile-apps {
      position: relative;
      z-index: 100;
      top: 5rem;
      margin-top: -1rem;

      .stores {
        justify-content: flex-end;
        display: flex;
        align-items: center;

        i {
          margin: .5rem;
          width: 180px;
          height: 60px;
        }
      }

      .smartphone img {
        bottom: 0;
        width: 243px;
      }
    }
  }
}

// Bringo Morocco custom logo style
.bringoMorocco-theme {
  .hamburger-menu-mobile {
    .site-logo {
      background-image: url($imgPath + '/' + map-get($header, 'logo_img_mobile'));
    }
  }

  header .address-section .address-box {
      .address, .number, {
        padding: 0 15px;
      }

      .number {
        position: relative;
        width: 240px;

        span.street-number-optional {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          font-style: italic;
          color: #777;
        }
      }
  }
}

.store-logo-carousel {
  width: 904px;
  display: flex;
  align-items: center;

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }

  .slick-slide img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .slick-next {
    margin-right: -50px;
  }
  .slick-prev {
    margin-left: -50px;
  }

  @media (max-width: 992px) {
    .slick-arrow {
      display: none!important;
    }

    & {
      width: 100%;
      padding: 0 30px;
    }
  }
}

